<template>
  <div class="home">
    <!--   头部导航     -->
    <headerC :header_fixed="header_fixed" :nav_active="nav_active"></headerC>

    <!--    轮播图    -->
    <div style="margin-top: 100px" class="head-top">
      <el-carousel
        trigger="click"
        arrow="hover"
        :indicator-position="screenWidth <= 1400 ? 'outside' : ''"
        :class="{ 'mobile-carousel': screenWidth <= 1400 }"
      >
        <!--    移动端banner       -->

        <el-carousel-item v-if="screenWidth <= 1400" class="mobile">
          <img
            src="../assets/header-phone-1.png"
            alt=""
            style="width: 100%; height: 100%"
          />
        </el-carousel-item>

        <el-carousel-item v-if="screenWidth <= 1400" class="mobile">
          <img
            src="../assets/header-phone-2.png"
            alt=""
            style="width: 100%; height: 100%"
          />
        </el-carousel-item>

        <!--    pc端banner     -->

        <el-carousel-item v-if="screenWidth > 1400" class="pc">
          <img src="../assets/header-pc-1.png" alt="" style="width: 100%" />
        </el-carousel-item>

        <el-carousel-item v-if="screenWidth > 1400" class="pc">
          <img src="../assets/header-pc-2.png" alt="" style="width: 100%" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 
    <el-carousel indicator-position="outside">
    <el-carousel-item v-for="item in 4" :key="item">
      <h3>{{ item }}</h3>
    </el-carousel-item>
  </el-carousel> -->
    <!-- 介绍 -->
    <introduce></introduce>

    <!-- 产品轮播图 -->
    <carousel></carousel>

    <!-- 源码介绍图 -->

    <!--  源码系统介绍图  -->
    <codeImg :home="home"></codeImg>
    <div
      ref="bgDiv"
      :style="{ height: bgDiv + 'px' }"
      v-if="screenWidth > 1440"
      class="introduce-pro-pc"
      @click="toDetails"
    >
      <img src="../assets/home/more.png" alt="" class="more-img" />
    </div>
    <div v-if="screenWidth <= 1440" class="introduce-pro-mobile">
      <img
        src="../assets/home/code-bottom-mobile.png"
        alt=""
        class="more-img"
      />
    </div>

    <footerC></footerC>
  </div>
</template>

<script>
let _this;
import headerC from "@/components/headerC.vue";
import footerC from "@/components/footerC.vue";
import introduce from "@/views/introduce.vue";
import carousel from "@/views/carousel.vue";
import codeImg from "@/views/codeImg.vue";

export default {
  name: "Home",
  components: {
    headerC,
    footerC,
    introduce,
    carousel,
    codeImg,
  },
  data() {
    return {
      home: "home",
      mobileDescriptions: [
        "用户端小程序",
        "用户端Android",
        "用户端IOS",
        "司机端iOS",
        "司机端Android",
        "企业版小程序",
        "老板端iOS",
        "老板端Android",
        "分城市管理系统",
        "全国超级管理系统",
      ],
      pcDescriptions: [],
      software_show: "driver",
      problem_hover: "",

      bgDiv: 100,

      screenWidth: document.body.clientWidth, // 屏幕宽

      // 显示二维码
      QRCode_show: "",

      scroll: "",
      header_fixed: false,
      nav_active: "index",

      //    自定义滚动条样式
      ops_mobile: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: false,
          specifyBorderRadius: "0",
        },
        bar: {
          specifyBorderRadius: "0",
          keepShow: false,
          onlyShowBarOnScroll: false,
          hoverStyle: false,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "", //滚动条颜色
          opacity: 1, //滚动条透明度
          "overflow-x": "hidden",
        },
      },

      //    自定义滚动条样式
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
          specifyBorderRadius: "0",
        },
        bar: {
          specifyBorderRadius: "0",
          keepShow: true,
          onlyShowBarOnScroll: false,
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#ffffff", //滚动条颜色
          opacity: 1, //滚动条透明度
          "overflow-x": "hidden",
        },
      },
    };
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.menu);

    // 监听窗口宽度变化
    window.addEventListener("resize", function () {
      window.screenWidth = document.body.clientWidth;
      _this.screenWidth = window.screenWidth;
      // console.log(_this.$refs.bgDiv.clientWidth);
      _this.bgDiv = Math.round(_this.$refs.bgDiv.clientWidth / 5.05);
      // console.log(_this.bgDiv,'_this.bgDiv---');
    });

    _this.bgDiv = Math.round(_this.$refs.bgDiv.clientWidth / 5.05);
  },
  watch: {
    scroll() {
      if (_this.scroll > 0) {
        _this.header_fixed = true;
      } else {
        _this.header_fixed = false;
      }
    },
  },
  created() {
    // console.log(_this)
    _this = this;
  },
  methods: {
    // 点击了解更多
    toDetails() {
      this.$router.push({ path: "/goodscode" });
      // 页面加载完成后滚动到顶部
      window.scrollTo(0, 0);
    },

    goNav(e) {
      this.$router.push({ path: e });
    },

    menu() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(this.scroll)
    },
    // 软件介绍点击事件
    softwareChange(val) {
      _this.software_show = val;
    },
    problemHover(val) {
      _this.problem_hover = val;
    },
    problemLeave() {
      _this.problem_hover = "";
    },
  },
};
</script>
<style>
.head-top .mobile-carousel .el-carousel__container {
  width: 100% !important;
  margin: 0 auto;
  height: calc(100vw * 1.2) !important;
}

.head-top .el-carousel__container {
  width: 100% !important;
  margin: 0 auto;
  /* height: calc(100vw / 2) !important; */
  height: calc(74vw / 2) !important;
}

/* .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  } */

.head-top .el-carousel.mobile-carousel .el-carousel__indicators--horizontal {
  position: unset !important;
  left: 160px !important;
  bottom: 0 !important;
  transform: translateX(0) !important;
}

.head-top .el-carousel .el-carousel__indicators--horizontal {
  position: absolute !important;
  left: 160px !important;
  bottom: 0 !important;
  transform: translateX(0) !important;
}

/* .el-carousel__indicator {
  .el-carousel__button {
    width: 52px;
    height: 6px;
    background-color: #cee2ff;
  }
}
.el-carousel__indicator.is-active {
  .el-carousel__button {
    background-color: #0b6fff;
  }
} */
.el-carousel__indicator .el-carousel__button {
  width: 52px;
  height: 6px;
  background-color: #cee2ff;
}

.el-carousel__indicator.is-active .el-carousel__button {
  background-color: #0b6fff;
}
</style>
<style lang="scss" scoped>
.introduce-pro-pc {
  width: 100%;
  position: relative;
  // 背景图长1920px,高380px,长大约是宽的5倍，为了保证图片跟随浏览器等比例缩放，设置高是屏幕可视宽度/5
  // height: calc(100vw / 5);

  background-image: url("../assets/home/code-bottom.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .more-img {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 61px;
    // 了解更多图片宽度388/1920（背景图片）算出背景图片的20.21%=了解更多图片宽度
    width: 20.21%;
    // 了解更多图片宽388px,背景图片1920/388 是4.94；了解更多图片高90px,背景图片1920/90 是21.33
    // width: calc(100vw / 4.94);
    // img标签想要成比例缩放，设置宽就行，不需要设置高
    // height: calc(100vw / 21.33);
  }
}

.introduce-pro-mobile {
  .more-img {
    // 默认是inline-block,会导致图片下面有一个白边，所以需要设置为block
    display: block;
    width: 100%;
  }
}
</style>
