<template>
  <!-- 九州产品介绍 轮播图 -->
  <div>
    <div class="new">
      <!-- style="height: auto;" 移除固定高度，允许自动扩展 -->
      <el-carousel
        trigger="click"
        arrow="hover"
        indicator-position="none"
        :autoplay="true"
        style="height: auto"
      >
        <el-carousel-item v-for="(item, index) in 5" :key="index">
          <div class="new-children">
            <h3>九州产品</h3>
            <p>九州研发团队 — 致力打造高效化、智能化代驾品牌</p>
            <div>
              <img
                v-if="screenWidth >= 1024"
                class="proImg"
                :src="require(`../assets/goods/goods-${index + 1}.png`)"
              />
              <img
                v-if="screenWidth < 1024"
                class="proImg"
                :src="require(`../assets/goods/goods-mobile-${index + 1}.png`)"
              />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

  </div>
</template>
<script>
let _this;

export default {
  name: "Home",
  components: {},

  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽

      scroll: "",
      header_fixed: false,
    };
  },

  mounted() {
    // console.log("mounted");
    // 监听页面滚动事件
    // window.addEventListener("scroll", this.menu);

    // 监听窗口宽度变化
    window.addEventListener("resize", function () {
      window.screenWidth = document.body.clientWidth;
      _this.screenWidth = window.screenWidth;
    });
  },
  watch: {
 
  },
  created() {
    // console.log(_this)
    _this = this;
  },
  methods: {

  },
};
</script>
<style>
@media screen and (min-width: 1px) and (max-width: 768px) {
  .new .el-carousel__container {
    width: 100% !important;
    margin: 0 auto;
    /* height: calc(100vw / 2) !important; */
    /* height: calc(116vw / 2) !important; */
    /* background-color: aqua; */
    height: 1138px;
  }


}

@media screen and (min-width: 768px) and (max-width: 1023px) {
   .new .el-carousel__container {
    width: 100% !important;
    margin: 0 auto;
    /* height: calc(100vw / 2) !important; */
    /* height: calc(116vw / 2) !important; */
    /* background-color: aqua; */
    height: 1138px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1399px) {
  .new .el-carousel__container {
    width: 90% !important;
    margin: 0 auto;
    /* height: calc(100vw / 2) !important; */
    /* height: calc(116vw / 2) !important; */
    /* background-color: aqua; */
    height: 1138px;
  }
}

@media screen and (min-width: 1400px) {
  .new .el-carousel__container {
    width: 100% !important;
    margin: 0 auto;
    /* height: calc(100vw / 2) !important; */
    /* height: calc(116vw / 2) !important; */
    /* background-color: aqua; */
    height: 1138px;
  }
  .software-max .el-carousel__container {
    width: 100% !important;
    margin: 0 auto;
    /* height: calc(100vw / 2) !important; */
    height: calc(116vw / 2) !important;
  }
}
</style>
<style lang="scss" scoped>
.software-max {
  background-color: #f0f4f7;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new {
    background-color: #f0f4f7;
    // max-width: 1400px;
    height: 548px;
    margin: 0 auto;
    .new-children {
      margin: 0 auto;
      padding-top: 20px;
      h3 {
        text-align: center;
        font-family: MicrosoftYaHei;
        color: #121419;
        font-weight: 400;
        font-size: 6vw;
      }
      p {
        text-align: center;
        font-family: Microsoft YaHei;
        font-weight: 300;
        font-size: 3vw;
        color: #606060;
        line-height: 55px;
        margin-top: 10px;
      }
      div {
        text-align: center;
        .proImg {
          width: 387px;
          // height: 800px;
        }

        // background-color:yellow;
      }
    }
  }

}

@media screen and (min-width: 768px) and (max-width: 1023px) {
   .new {
    background-color: #f0f4f7;
    max-width: 90%;
    height: 1020px;
    margin: 0 auto;
    .new-children {
      margin: 0 auto;
      padding-top: 40px;
      h3 {
        text-align: center;
        font-family: MicrosoftYaHei;
        color: #121419;
        font-weight: 400;
        font-size: 40px;
      }
      p {
        text-align: center;
        font-family: Microsoft YaHei;
        font-weight: 300;
        font-size: 20px;
        color: #606060;
        line-height: 55px;
        margin-top: 20px;
      }
      div {
        text-align: center;
        margin-top: 22px;
        // background-color:yellow;
        .proImg {
          width: 80%;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1399px) {
  .new {
    background-color: #f0f4f7;
    max-width: 90%;
    height: 1020px;
    margin: 0 auto;
    .new-children {
      margin: 0 auto;
      padding-top: 40px;
      h3 {
        text-align: center;
        font-family: MicrosoftYaHei;
        color: #121419;
        font-weight: 400;
        font-size: 40px;
      }
      p {
        text-align: center;
        font-family: Microsoft YaHei;
        font-weight: 300;
        font-size: 20px;
        color: #606060;
        line-height: 55px;
        margin-top: 20px;
      }
      div {
        text-align: center;
        margin-top: 22px;
        // background-color:yellow;
        .proImg {
          width: 96%;
        }
      }
    }
  }

}

@media screen and (min-width: 1400px) {
  .new {
    background-color: #f0f4f7;
    max-width: 1400px;
    height: 1138px;
    margin: 0 auto;
    .new-children {
      margin: 0 auto;
      padding-top: 48px;
      h3 {
        text-align: center;
        font-family: MicrosoftYaHei;
        color: #121419;
        font-weight: 400;
        font-size: 46px;
      }
      p {
        text-align: center;
        font-family: Microsoft YaHei;
        font-weight: 300;
        font-size: 24px;
        color: #606060;
        line-height: 55px;
        margin-top: 26px;
      }
      div {
        text-align: center;
        margin-top: 22px;
        // background-color:yellow;
      }
    }
  }

}
</style>
