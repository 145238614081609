import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";

import "element-ui/lib/theme-chalk/index.css"; // 默认主题
// import './assets/css/theme-green/index1.css'; // 浅绿色主题
import vuescroll from "vuescroll"; //引入vuescroll
import "vuescroll/dist/vuescroll.css"; //引入vuescroll样式
Vue.use(vuescroll); //使用
Vue.config.productionTip = false;
Vue.use(ElementUI, {
  size: "small",
});

router.beforeEach((to, from, next) => {
  if (to.path == "/login" && localStorage.getItem("access_token") != null) {
    next("/index");
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
