<template>
  <!-- 该组件在首页和源码中心被引用 -->
  <div class="join">
    <!--精品推荐 pc端-->
    <div class="goods-introduce">
      <div class="introduce-img">
        <!-- 精品推荐图片 -->
        <img
          class="title-img"
          src="../assets/jingpin-pc.png"
          alt="精品推荐图片"
        />
      </div>

      <!-- 产品介绍图 -->
      <ul class="goods-introduce-ul">
        <li ref="goodsUlLi" v-for="(item, index) in goods" :key="index">
          <img
            class="imgPC"
            ref="imgPC"
            :src="require(`../assets/code/${item.img}`)"
            :alt="`产品介绍图 ${index + 1}`"
          />

          <h3>{{ item.title }}</h3>
          <p>
            <span>{{ item.price }}</span
            ><span style="color: #b9b9b9">{{ item.priceOld }}</span>
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
let _this;

export default {
  props: {
    home: {
      type: String,
      default: "",
    },
  },

  watch: {},

  components: {},
  created() {
    _this = this;
  },
  data() {
    return {
      goods: [
        {
          img: "goods-1.png",
          title: "上门按摩系统源码",
          price: "￥12800",
          priceOld: "￥19000",
        },
        {
          img: "goods-2.png",
          title: "租车系统源码",
          price: "￥7800",
          priceOld: "￥9800",
        },
        {
          img: "goods-3.png",
          title: "智慧物业系统源码",
          price: "￥7800",
          priceOld: "￥9800",
        },
        {
          img: "goods-4.png",
          title: "代驾系统源码",
          price: "￥128000",
          priceOld: "￥150000",
        },
        {
          img: "goods-5.png",
          title: "社区团购系统源码",
          price: "￥7800",
          priceOld: "￥9800",
        },
        {
          img: "goods-6.png",
          title: "同城跑腿系统源码",
          price: "￥12800",
          priceOld: "￥15000",
        },
        {
          img: "goods-7.png",
          title: "聊天机器人系统源码",
          price: "￥12800",
          priceOld: "￥15800",
        },
      ],
    };
  },

  mounted() {
    if (_this.home === "home") {
      _this.goods = [
        {
          img: "goods-1.png",
          title: "上门按摩系统源码",
          price: "￥12800",
          priceOld: "￥19000",
        },
        {
          img: "goods-2.png",
          title: "租车系统源码",
          price: "￥7800",
          priceOld: "￥9000",
        },
        {
          img: "goods-3.png",
          title: "智慧物业系统源码",
          price: "￥7800",
          priceOld: "￥9800",
        },
        {
          img: "goods-4.png",
          title: "代驾系统源码",
          price: "￥128000",
          priceOld: "￥150000",
        },
      ];
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.goods-introduce-ul {
  display: flex;
  flex-wrap: wrap;
}

/* 响应式布局 */
@media screen and (min-width: 1px) and (max-width: 768px) {
  .goods-introduce {
    width: 90%;
    margin: 0 auto;

    /* 设置精品文字图片外边距 */
    .introduce-img {
      margin-top: 10px;
      margin-bottom: 10px;

      /* 设置精品文字图片大小 */
      .title-img {
        width: 200px;
        height: auto;
      }
    }

    .goods-introduce-ul {
      margin-bottom: 20px;
      justify-content: space-between;

      li {
        flex-basis: 46%;
        padding-left: 10px;
        width: 170px;

        img.imgPC {
          width: 96%;
          height: auto;
        }

        h3 {
          /* text-align: center; */
          /* 根据浏览器宽度，文字大小动态改变 */
          font-size: 3vw;
          font-weight: 400;
          color: #121419;
          margin-bottom: 6px;
          margin-top: 6px;
        }

        p {
          /* text-align: center; */
          /* 根据浏览器宽度，文字大小动态改变 */
          font-size: 3vw;
          color: #ff6f00;

          span:nth-child(2) {
            text-decoration: line-through;
            /* 根据浏览器宽度，文字大小动态改变 */
            font-size: 2.4vw;
            color: #b9b9b9;
            margin-left: 4px;
          }
        }

        /* 第一排图片距离下面图片的间距 */
        &:nth-child(-n + 2) {
          margin-bottom: 10px;
        }

        /* 设置4的倍数产品图片的外边距 */
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .goods-introduce {
    width: 90%;
    margin: 0 auto;

    /* 设置精品文字图片外边距 */
    .introduce-img {
      margin-top: 10px;
      margin-bottom: 10px;

      /* 设置精品文字图片大小 */
      .title-img {
        width: 300px;
        height: auto;
      }
    }

    .goods-introduce-ul {
      margin-bottom: 20px;
      justify-content: space-between;

      li {
        flex-basis: 30%;
        padding-left: 10px;
        width: 170px;

        img.imgPC {
          width: 96%;
          height: auto;
        }

        h3 {
          /* text-align: center; */
          /* 根据浏览器宽度，文字大小动态改变，，文字大小动态改变 大小是p的30% */
          font-size: 2.4vw;
          font-weight: 400;
          color: #121419;
          margin-bottom: 6px;
          margin-top: 6px;
        }

        p {
          /* text-align: center; */
          /* 根据浏览器宽度，文字大小动态改变 */
          font-size: 3vw;
          color: #ff6f00;

          span:nth-child(2) {
            text-decoration: line-through;
            /* 根据浏览器宽度，文字大小动态改变，，文字大小动态改变 大小是p的30% */
            font-size: 2.4vw;
            color: #b9b9b9;
            margin-left: 4px;
          }
        }

        /* 第一排图片距离下面图片的间距 */
        &:nth-child(-n + 2) {
          margin-bottom: 10px;
        }

        /* 设置4的倍数产品图片的外边距 */
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1339px) {
  .goods-introduce {
    width: 90%;
    margin: 0 auto;

    /* 设置精品文字图片外边距 */
    .introduce-img {
      margin-top: 10px;
      margin-bottom: 10px;

      /* 设置精品文字图片大小 */
      .title-img {
        width: 300px;
        height: auto;
      }
    }

    .goods-introduce-ul {
      margin-bottom: 20px;
      justify-content: space-between;

      li {
        flex-basis: 30%;
        padding-left: 10px;
        width: 170px;

        img.imgPC {
          width: 96%;
          height: auto;
        }

        h3 {
          /* text-align: center; */
          /* 根据浏览器宽度，文字大小动态改变 大小是p的30% */
          font-size: 1.9vw;
          font-weight: 400;
          color: #121419;
          margin-bottom: 6px;
          margin-top: 6px;
        }

        p {
          /* text-align: center; */
          /* 根据浏览器宽度，文字大小动态改变 */
          font-size: 2.4vw;
          color: #ff6f00;

          span:nth-child(2) {
            text-decoration: line-through;
            /* 根据浏览器宽度，文字大小动态改变，文字大小动态改变 大小是p的30% */
            font-size: 1.9vw;
            color: #b9b9b9;
            margin-left: 4px;
          }
        }

        /* 第一排图片距离下面图片的间距 */
        &:nth-child(-n + 2) {
          margin-bottom: 10px;
        }

        /* 设置2的倍数产品图片的外边距 */
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .goods-introduce {
    /* 设置产品图片父元素宽度 */
    max-width: 1400px;
    margin: 40px auto 0;

    .introduce-img {
      /* 设置精品文字图片外边距 */

      margin-bottom: 31px;

      /* 设置精品文字图片大小 */
      .title-img {
        width: 337px;
        height: auto;
      }
    }

    .goods-introduce-ul {
      margin: 0 auto;

      li {
        padding: 2px;
        margin-right: 14px;
        border: 1px solid transparent;

        &:hover {
          border: 1px solid #2b80ff;
        }

        width: 332px;

        h3 {
          /* text-align: center; */
          font-size: 24px;
          font-weight: 400;
          color: #121419;
          margin-bottom: 14px;
          margin-top: 14px;
        }

        p {
          /* text-align: center; */
          font-size: 30px;
          color: #ff6f00;

          span:nth-child(2) {
            text-decoration: line-through;
            font-size: 24px;
            color: #b9b9b9;
            margin-left: 4px;
          }
        }

        /* 第一排图片距离下面图片的间距 */
        &:nth-child(-n + 4) {
          margin-bottom: 20px;
        }

        /* 设置4的倍数产品图片的外边距 */
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
