<template>
  <div
    class="header-wrap fixed"
    :class="{
      active: header_fixed && screenWidth > 1440,
      mobile: screenWidth <= 1440,
    }"
  >
    <div v-if="screenWidth <= 1440" class="header">
      <img
        src="@/assets/logo-active.png"
        alt=""
        class="logo-mobile"
        @click="goNav('/')"
      />
      <div class="nav-btn" v-if="screenWidth <= 1440" @click="navClick">
        <img src="../assets/nav-btn.png" alt="" />
      </div>

      <transition name="nav">
        <div class="nav-mobile" v-if="screenWidth <= 1440 && !nav_btn">
          <div class="nav-head">
            <img src="@/assets/logo.png" alt="" class="logo" />
            <img
              src="../assets/nav-btn-close.png"
              alt=""
              @click="navClick"
              class="close-btn"
            />
          </div>
          <ul>
            <li :class="{ active: nav_active == 'index' }" @click="goNav('/')">
              <p>首页</p>
            </li>
            <li
              :class="{ active: nav_active == 'goodsCode' }"
              @click="goNav('goodsCode')"
            >
              <p>源码中心</p>
              <p @click.stop="navItemClick(1)"></p>
            </li>
            <li
              :class="{ active: nav_active == 'join' }"
              @click="goNav('join')"
            >
              <p>招商加盟</p>
              <p @click.stop="navItemClick(2)">
                {{ nav_item_index == 1 ? "—" : "+" }}
              </p>
            </li>
            <li class="item-children" v-if="nav_item_index == 2">
              <p>· 加盟背景</p>
              <p>· 加盟优势</p>
              <p>· 加盟费用</p>
              <p>· 加盟条件</p>
              <p>· 合作流程</p>
            </li>
            <li
              :class="{ active: nav_active == 'problem' }"
              @click="goNav('problem')"
            >
              <p>常见问题</p>
              <p @click.stop="navItemClick(3)">
                {{ nav_item_index == 1 ? "—" : "+" }}
              </p>
            </li>
            <li class="item-children" v-if="nav_item_index == 3">
              <p>· 软件问题</p>
            </li>
            <li
              :class="{ active: nav_active == 'about' }"
              @click="goNav('/about')"
            >
              <p>关于我们</p>
              <p @click.stop="navItemClick(1)">
                {{ nav_item_index == 1 ? "—" : "+" }}
              </p>
            </li>

            <li class="item-children" v-if="nav_item_index == 1">
              <p>· 企业简介</p>
              <p>· 发展历程</p>
              <p>· 团队介绍</p>
              <p>· 合作伙伴</p>
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <div class="header" style="color: #121419; max-width: 1400px">
      <ul v-if="screenWidth > 1440">
        <li>
          <img
            style="vertical-align: middle"
            src="@/assets/logo-active.png"
            alt=""
            class="logo"
            @click="goNav('/')"
          />
        </li>

        <li :class="{ active: nav_active == 'index' }" @click="goNav('/')">
          首页
        </li>
        <li
          :class="{ active: nav_active == 'goodsCode' }"
          @click="goNav('goodsCode')"
        >
          源码中心
        </li>
        <li :class="{ active: nav_active == 'join' }" @click="goNav('join')">
          招商加盟
        </li>

        <li
          :class="{ active: nav_active == 'problem' }"
          @click="goNav('problem')"
        >
          常见问题
        </li>
        <li :class="{ active: nav_active == 'about' }" @click="goNav('about')">
          关于我们
        </li>
        <li>
          <img
            style="vertical-align: middle"
            class="phone-wrap"
            src="../assets/phone.png"
            alt=""
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
let _this;
export default {
  name: "headerC",
  props: {
    header_fixed: Boolean,
    nav_active: "",
  },
  watch: {
    header_fixed() {},
  },
  created() {
    _this = this;
  },
  mounted() {
    window.addEventListener("resize", function () {
      window.screenWidth = document.body.clientWidth;
      _this.screenWidth = window.screenWidth;
    });
  },
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      nav_btn: true,
      nav_item_index: "",
    };
  },
  methods: {
    goNav(e) {
      this.$router.push({ path: e });
    },

    // 导航点击事件
    navItemClick(index) {
      if (_this.nav_item_index == index) {
        _this.nav_item_index = "";
      } else {
        _this.nav_item_index = index;
      }
    },
    navClick() {
      _this.nav_btn = !_this.nav_btn;
    },
  },
};
</script>

<style lang="scss" scoped>
.fixed {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: transparent;
}

img.phone-wrap {
  width: 100%;
  height: auto;
}

img.logo-mobile {
  width: 130px;
  height: 40px;
}
img.logo {
  width: 100%;
  height: auto;
}

.header-wrap {
  width: 100%;

  padding: 30px 0;
  box-sizing: border-box;
  background-color: transparent;
  background-color: #ffffff;
  transition: 0.5s;

  .header {
    margin: 0 auto;

    & > ul {
      display: flex;
      justify-content: space-between;
      align-items: center;

      li {
        font-size: 20px;
        box-sizing: border-box;
        text-align: center;

        cursor: pointer;
      }

      .active {
        border-bottom: 2px solid #2b80ff;
        font-weight: bold;
        color: #2b80ff;
      }
    }

    & > span {
      display: block;
      background-color: rgba(255, 255, 255, 0.3);
      margin: 0 3.5%;
      width: 1px;
      height: 24px;
    }
  }
}

.header-wrap.mobile {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  padding: 30px 0;
  box-sizing: border-box;
  background-color: transparent;

  .header {
    display: flex;
    justify-content: space-between;
    height: 56px;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .nav-btn {
    width: 40px;
    height: 40px;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .nav-mobile {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: #0a1837;
    padding: 0 30px !important;
    box-sizing: border-box;
    overflow-y: auto;

    .nav-head {
      display: flex;
      justify-content: space-between;
      padding: 31px 0;
      align-items: center;
      height: 56px;

      /*box-sizing: border-box;*/
      .close-btn {
        width: 40px;
        height: 40px;
      }
    }

    & > ul {
      & > li {
        height: 80px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);

        p {
          font-size: 20px;
          font-weight: 100;
          color: #ffffff;
        }
      }

      li.active {
        p {
          color: #0b6fff;
        }
      }

      li.item-children {
        border: 0;
        background-color: rgba(255, 255, 255, 0.15);
        border-radius: 0px 0px 10px 10px;
        display: block;
        height: auto;
        padding: 0 20px;
        box-sizing: border-box;

        p {
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
          height: 40px;
          line-height: 40px;
        }
      }
    }
  }

  .nav-enter {
    opacity: 0;
  }

  .nav-leave {
    opacity: 1;
  }

  .nav-enter-active {
    transition: opacity 0.5s;
  }

  .nav-leave-active {
    opacity: 0;
    transition: opacity 0.5s;
  }
}
</style>
