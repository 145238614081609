<template>
  <!-- 版心 -->
  <div>
    <!--    介绍pc端    -->
    <div class="introduce-max-pc" v-if="screenWidth > 1400">
      <div class="introduce">
        <div>
          <img src="../assets/introduce-1.png" alt="" />
          <h4>划圈计费</h4>
          <p>
            <span>计费更省心</span><span style="margin-left: 10px">系统</span
            ><span style="color: #2b80ff">透明化</span>
          </p>
        </div>

        <div>
          <img src="../assets/introduce-2.png" alt="" />
          <h4>AI派单</h4>
          <p>
            <span>智能派单</span
            ><span style="margin-left: 10px; color: #2b80ff">一分钟</span
            ><span>响应</span>
          </p>
        </div>

        <div>
          <img src="../assets/introduce-3.png" alt="" />
          <h4>全天售后</h4>
          <p>
            <span>提供</span
            ><span style="margin-left: 10px; color: #2b80ff">24小时</span
            ><span>售后服务</span>
          </p>
        </div>

        <div>
          <img src="../assets/introduce-4.png" alt="" />
          <h4>省心安全</h4>
          <p>
            <span style="margin-right: 10px; color: #2b80ff">百万保险</span
            ><span>保驾护航</span>
          </p>
        </div>

        <div>
          <img src="../assets/introduce-5.png" alt="" />
          <h4>平台可靠</h4>
          <p>
            <span>全国</span><span style="color: #2b80ff">270+加盟</span
            ><span>城市</span>
          </p>
        </div>
      </div>
    </div>

    <!--    介绍移动端    -->
    <vue-scroll :ops="ops_mobile" v-if="screenWidth <= 1400">
      <div class="introduce-max-phone">
        <div class="introduce">
          <div>
            <img src="../assets/introduce-1.png" alt="" />
            <h4>划圈计费</h4>
            <p>
              <span>计费更省心</span><span style="margin-left: 10px">系统</span
              ><span style="color: #2b80ff">透明化</span>
            </p>
          </div>

          <div>
            <img src="../assets/introduce-2.png" alt="" />
            <h4>AI派单</h4>
            <p>
              <span>智能派单</span
              ><span style="margin-left: 10px; color: #2b80ff">一分钟</span
              ><span>响应</span>
            </p>
          </div>

          <div>
            <img src="../assets/introduce-3.png" alt="" />
            <h4>全天售后</h4>
            <p>
              <span>提供</span
              ><span style="margin-left: 10px; color: #2b80ff">24小时</span
              ><span>售后服务</span>
            </p>
          </div>

          <div>
            <img src="../assets/introduce-4.png" alt="" />
            <h4>省心安全</h4>
            <p>
              <span style="margin-right: 10px; color: #2b80ff">百万保险</span
              ><span>保驾护航</span>
            </p>
          </div>

          <div>
            <img src="../assets/introduce-5.png" alt="" />
            <h4>平台可靠</h4>
            <p>
              <span>全国</span><span style="color: #2b80ff">270+加盟</span
              ><span>城市</span>
            </p>
          </div>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>
<script>
let _this;
import headerC from "@/components/headerC.vue";
import footerC from "@/components/footerC.vue";
// import introduce from "@/components/introduce.vue";
// import carousel from "@/components/carousel.vue";

export default {
  name: "Home",
  components: {
    headerC,
    footerC,
    // introduce,
    // carousel,
  },
  data() {
    return {
      mobileDescriptions: [
        "用户端小程序",
        "用户端Android",
        "用户端IOS",
        "司机端iOS",
        "司机端Android",
        "企业版小程序",
        "老板端iOS",
        "老板端Android",
        "分城市管理系统",
        "全国超级管理系统",
      ],
      pcDescriptions: [],
      software_show: "driver",
      problem_hover: "",

      screenWidth: document.body.clientWidth, // 屏幕宽

      // 显示二维码
      QRCode_show: "",

      scroll: "",
      header_fixed: false,
      nav_active: "index",

      //    自定义滚动条样式
      ops_mobile: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: false,
          specifyBorderRadius: "0",
        },
        bar: {
          specifyBorderRadius: "0",
          keepShow: false,
          onlyShowBarOnScroll: false,
          hoverStyle: false,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "", //滚动条颜色
          opacity: 1, //滚动条透明度
          "overflow-x": "hidden",
        },
      },

      //    自定义滚动条样式
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
          specifyBorderRadius: "0",
        },
        bar: {
          specifyBorderRadius: "0",
          keepShow: true,
          onlyShowBarOnScroll: false,
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#ffffff", //滚动条颜色
          opacity: 1, //滚动条透明度
          "overflow-x": "hidden",
        },
      },
    };
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.menu);

    // 监听窗口宽度变化
    window.addEventListener("resize", function () {
      window.screenWidth = document.body.clientWidth;
      _this.screenWidth = window.screenWidth;
    });
  },
  watch: {
    scroll() {
      if (_this.scroll > 0) {
        _this.header_fixed = true;
      } else {
        _this.header_fixed = false;
      }
    },
  },
  created() {
    // console.log(_this)
    _this = this;
  },
  methods: {
    goNav(e) {
      this.$router.push({ path: e });
    },

    menu() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(this.scroll)
    },
    // 软件介绍点击事件
    softwareChange(val) {
      _this.software_show = val;
    },
    problemHover(val) {
      _this.problem_hover = val;
    },
    problemLeave() {
      _this.problem_hover = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.lunbo {
  overflow: hidden;
  .el-carousel__container {
    display: flex !important;
  }
  .el-carousel__item {
    display: flex;
    justify-content: space-between;
    & > div {
      width: 30%;
      & > div {
        position: relative;
        .Mask-bg {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transition: 0.3s;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
      img {
        width: 100%;
      }
      p {
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        color: #dbeaff;
        font-weight: 400;
        opacity: 0.3;
        transition: 0.3s;
      }
    }
    & > div:hover {
      & > div {
        .Mask-bg {
          background-color: rgba(0, 0, 0, 0);
        }
      }
      p {
        opacity: 1;
      }
    }
  }
}
.el-carousel__item.mobile:nth-of-type(1) {
  background: url("../assets/header-phone-1.png") no-repeat;
  background-size: 100%;
}

.el-carousel__item.mobile:nth-of-type(2) {
  background: url("../assets/header-phone-2.png") no-repeat;
  background-size: 100%;
}
.el-carousel__item.pc:nth-of-type(1) {
  background: url("../assets/header-pc-1.png") no-repeat;
  background-size: 100%;
}

.el-carousel__item.pc:nth-of-type(2) {
  background: url("../assets/header-pc-2.png") no-repeat;
  background-size: 100%;
}
.pc-carousel {
  width: 100%;
  margin-top: 100px;
}
.mobile-carousel {
  // width: 750px;
  // height: 515px;
  width: 100%;
}

.banner-phone {
  width: 430px;
  height: 515px;
}
.introduce-max-pc {
  max-width: 1400;
  margin-top: 60px;
  margin-bottom: 71px;

  .introduce {
    max-width: 1400px;
    margin: 0 auto;
    // padding: 80px;
    display: flex;
    justify-content: space-between;

    & > div {
      margin: 0 30px;
      width: 150px;
    }
  }

  & > div {
    img {
      display: block;
      width: 60px;
      margin: 0 auto 4px;
    }

    h4 {
      text-align: center;
      margin-bottom: 6px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
    }

    p {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
  }
}
.introduce-max-phone {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 130px;

  .introduce {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    & > div {
      margin: 0 30px;
      width: 150px;
    }
  }

  & > div {
    img {
      display: block;
      width: 60px;
      margin: 0 auto;
    }

    h4 {
      text-align: center;
      margin-bottom: 6px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
    }

    p {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
  }
}

// .software-max {
//   background-color: #f0f4f7;
//   width: 700px;
//   height: 569px;
// }
.software-max {
  width: 100%;
  // background-image: url("../assets/software-bg.png");
  // background-size: 100%;
  // background-repeat: no-repeat;
  // height: 1158px;

  .software {
    max-width: 1400px;
    margin: 100px auto 0;

    .title {
      margin-bottom: 95px;
      h3 {
        text-align: center;
        font-size: 42px;
        font-weight: 400;
        color: #121419;
      }

      p {
        text-align: center;
        font-family: Microsoft YaHei;
        letter-spacing: 5px;
        margin-top: 20px;
        font-size: 18px;
        font-weight: 400;
        color: #606060;
      }
    }
  }
}

.software-max.mobile {
  background-position: bottom;
  height: auto;
  padding-bottom: 50px;
  box-sizing: border-box;

  .title {
    h3 {
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      color: #333333;
    }

    p {
      text-align: center;
      font-family: Microsoft YaHei;
      letter-spacing: 2px;
      margin-top: 10px;
      font-size: 12px;
      font-weight: 400;
      color: #888888;
    }
  }
}

.scene-max.mobile {
  background-position: bottom;
  background-image: url("../assets/scene-bg-phone.png");

  .scene {
    margin: 0 auto;
    transform: translateY(0);

    .title {
      h3 {
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        color: #333333;
      }

      p {
        text-align: center;
        font-family: Microsoft YaHei;
        letter-spacing: 3px;
        margin-top: 20px;
        font-size: 12px;
        font-weight: 400;
        color: #888888;
      }
    }

    .scene-wrap {
      .scene-item {
        width: 250px;
        margin: 0 15px;

        div {
          padding: 15px 25px;

          h4 {
            font-size: 16px;
            margin-bottom: 15px;
          }

          p {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.scene-max {
  width: 100%;
  background-image: url("../assets/scene-bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 443px;

  .scene {
    max-width: 1400px;
    margin: 0 auto;
    transform: translateY(-250px);

    .title {
      h3 {
        text-align: center;
        font-size: 42px;
        font-weight: 400;
        color: #333333;
      }

      p {
        text-align: center;
        font-family: Microsoft YaHei;
        letter-spacing: 5px;
        margin-top: 20px;
        font-size: 18px;
        font-weight: 400;
        color: #888888;
      }
    }

    .scene-wrap {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;

      .scene-item {
        width: 31%;
        border-radius: 20px;
        background: #ffffff;
        max-width: 500px;

        img {
          width: 100%;
          display: block;
        }

        div {
          padding: 20px 40px;
          box-sizing: border-box;

          h4 {
            font-size: 24px;
            font-weight: 400;
            color: #333333;
            margin-bottom: 24px;
          }

          p {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
  }
}

.problem-max.mobile {
  .problem {
    .title {
      h3 {
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        color: #333333;
      }

      p {
        text-align: center;
        font-family: Microsoft YaHei;
        letter-spacing: 3px;
        margin-top: 20px;
        font-size: 12px;
        font-weight: 400;
        color: #888888;
      }
    }

    .problem-main {
      padding: 0 30px;
      box-sizing: border-box;

      .main-item {
        width: 100%;

        div {
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.problem-max {
  width: 100%;

  .problem {
    max-width: 1400px;
    margin: 118px auto 0;

    .title {
      h3 {
        text-align: center;
        font-size: 42px;
        font-weight: 400;
        color: #333333;
      }

      p {
        text-align: center;
        font-family: Microsoft YaHei;
        letter-spacing: 5px;
        margin-top: 20px;
        font-size: 18px;
        font-weight: 400;
        color: #888888;
      }
    }

    .problem-main {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .main-item {
        width: 49%;
        height: 70px;
        margin: 15px 0;
        background: #f8f8f8;
        display: flex;
        align-items: center;
        padding: 0 30px;
        box-sizing: border-box;
        justify-content: space-between;
        cursor: pointer;
        transition: 0.5s;

        &:hover {
          background: #ff9600;

          & > div > p {
            color: #fff;
          }
        }

        div {
          display: flex;
          align-items: center;

          & > img {
            width: 26px;
            height: 26px;
            display: block;
            margin-right: 20px;
          }

          p {
            font-size: 18px;
            font-weight: 400;
          }
        }

        & > img {
          float: right;
        }
      }
    }
  }
}

.city-max.mobile {
  .city {
    .title {
      h3 {
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        color: #ffffff;
      }

      p {
        text-align: center;
        font-family: Microsoft YaHei;
        letter-spacing: 5px;
        margin-top: 20px;
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
      }
    }

    .city-main {
      padding: 0 30px 60px;
      box-sizing: border-box;
      position: relative;

      & > div {
        width: 100%;
        height: 300px;
      }

      & > img {
        position: absolute;
        width: 80%;
        top: 330px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.city-max {
  width: 100%;
  background-image: url("../assets/city-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 120px;
  box-sizing: border-box;

  .city {
    max-width: 1400px;
    margin: 120px auto 0;
    padding-top: 120px;
    box-sizing: border-box;

    .title {
      h3 {
        text-align: center;
        font-size: 42px;
        font-weight: 400;
        color: #ffffff;
      }

      p {
        text-align: center;
        font-family: Microsoft YaHei;
        letter-spacing: 5px;
        margin-top: 20px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
      }
    }

    .city-main {
      display: flex;
      margin-top: 50px;
      position: relative;

      & > div {
        width: 60%;
        height: 367px;
        border: 1px solid rgba(255, 255, 255, 0.45);

        .left {
          padding: 0 40px;
          box-sizing: border-box;

          .city-item {
            height: 68px;
            line-height: 68px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            display: flex;

            .title {
              font-size: 18px;
              color: #ffffff;
              font-weight: bold;
              width: 86px;
            }

            ul {
              display: flex;

              li {
                width: 48px;
                margin: 0 10px;
                font-size: 16px;
                font-weight: 400;
                color: #ffffff;
              }
            }
          }
        }
      }

      & > img {
        width: 50%;
        max-width: 902px;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(100%, -60%);
      }
    }
  }
}
</style>
