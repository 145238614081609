<template>
  <div class="footer-max-new">
    <div v-if="screenWidth <= 1399" class="footer-max mobile">
      <div class="top">
        <div class="left">
          <div class="telphone" v-if="screenWidth <= 1440">
            <p>24小时叫车服务热线</p>
            <h3>8880-2111</h3>
          </div>

          <div class="phone">
            <h3>
              <img src="@/assets/phone-icon.png" alt="" />

              客服电话：
            </h3>
            <h3>400-0431639</h3>
          </div>

          <div class="phone-wrap" v-if="screenWidth > 1440">
            <div>
              联系邮箱：
              <span>jzhoudaijia@163.com</span>
            </div>
          </div>

          <div class="kefu-btn">
            <img src="../assets/kefu-icon.png" alt="" />
            在线客服
          </div>
        </div>
        <div class="center" v-if="screenWidth > 1440">
          <ul>
            <li @click="goNav('/about')">关于九州</li>
            <!-- <li>企业简介</li>
                        <li>应用介绍</li>
                        <li>发展历程</li>
                        <li>团队介绍</li>
                        <li>合作伙伴</li> -->
          </ul>
          <ul>
            <li @click="goNav('join')">招商加盟</li>
            <!-- <li>加盟背景</li>
                        <li>加盟优势</li>
                        <li>加盟费用</li>
                        <li>加盟条件</li>
                        <li>合作流程</li> -->
          </ul>
          <ul>
            <li @click="goNav('problem')">常见问题</li>
            <!-- <li>软件问题</li>
                        <li>加盟问题</li> -->
          </ul>
          <ul>
            <li @click="goNav('us')">加入我们</li>
            <!-- <li>招聘岗位</li> -->
          </ul>
          <ul>
            <li @click="goNav('contact')">联系方式</li>
            <!-- <li>联系方式</li> -->
          </ul>
        </div>
        <div class="right" v-if="screenWidth > 1440">
          <h3>客户端下载</h3>
          <el-carousel
            class="lunbo"
            trigger="click"
            height="150px"
            arrow="never"
            indicator-position="none"
          >
            <el-carousel-item>
              <div>
                <div>
                  <img src="../assets/android-driver.png" alt="" />
                  <div class="Mask-bg"></div>
                </div>
                <p>安卓司机端</p>
              </div>
              <div>
                <div>
                  <img src="../assets/android-driver.png" alt="" />
                  <div class="Mask-bg"></div>
                </div>
                <p>IOS司机端</p>
              </div>
              <div>
                <div>
                  <img src="../assets/android-consumer.png" alt="" />
                  <div class="Mask-bg"></div>
                </div>
                <p>安卓乘客端</p>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div>
                <div>
                  <img src="../assets/android-driver.png" alt="" />
                  <div class="Mask-bg"></div>
                </div>
                <p>IOS乘客端</p>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <span></span>

      <div class="bottom">
        <img src="../assets/logo-footer.png" alt="" />
        <div>
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030002000001"
          >
            <img
              src="../assets/footer-icon.png"
              style="vertical-align: middle"
            />

            <span style="color: #738099; margin-left: 4px"
              >吉公网安备 22017302000295号</span
            >
          </a>
          <div style="height: 60px; color: #738099">
            ©2020 吉林省盛世九州科技有限公司 版权所有 吉ICP备2021000546号-1
          </div>
        </div>
      </div>
    </div>
    <div v-if="screenWidth >= 1400" class="footer-box">
      <div class="footer-main">
        <div class="left">
          <!-- logo -->
          <div style="height: 86px">
            <img src="../assets/footer/footer-logo.png" alt="" />
          </div>
          <div style="margin-top: 69px">24h客服热线：</div>
          <div class="phone">400-0431-639</div>
          <div class="email">邮箱：jzhoudaiia@163.com</div>
          <div class="kefu">
            <img src="../assets/footer/kefu.png" alt="" />
          </div>
        </div>
        <div class="right">
          <ul>
            <li v-for="(item, index) in qrCode" :key="index">
              <div class="title">{{ item.title }}</div>
              <img
                class="imgPC"
                ref="imgPC"
                :src="require(`../assets/footer/${item.img}`)"
              />
            </li>
          </ul>
        </div>
      </div>
      <div class="bottom">
        <div style="width: 893px; margin: 0 auto">
          <img
            src="../assets/footer/footer-icon.png"
            alt=""
            style="vertical-align: middle; margin-right: 3px"
          />吉公网安备 22017302000295号 @2020 吉林省盛世九州科技有限公司
          版权所有 吉ICP备2021000546号-1
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let _this;
export default {
  name: "footerC",
  data() {
    return {
      qrCode: [
        {
          img: "ios-user.png",
          title: "九州代驾iOS用户端",
        },
        {
          img: "android-user.png",
          title: "九州代驾Android",
        },
        {
          img: "weixin-user.png",
          title: "九州代驾Android",
        },
        {
          img: "gongzhong.png",
          title: "九州代驾公众号",
        },
      ],
      screenWidth: document.body.clientWidth, // 屏幕宽
    };
  },
  created() {
    _this = this;
  },
  mounted() {
    window.addEventListener("resize", function () {
      window.screenWidth = document.body.clientWidth;
      _this.screenWidth = window.screenWidth;
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1px) and (max-width: 768px) {
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
}

@media screen and (min-width: 1400px) {
  .footer-max-new {
    background-color: #0a1633;

    .footer-box {
      padding-top: 80px;
    }

    .footer-main {
      max-width: 1400px;

      display: flex;
      justify-content: space-between;

      height: 550px;

      margin: 0 auto;

      .left {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 24px;
        color: #dbeaff;

        .phone {
          font-family: Source Han Serif CN;
          font-weight: 500;
          font-size: 53px;
          color: #dbeaff;

          margin-top: 14px;
        }

        .email {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 24px;
          color: #dbeaff;

          margin-top: 28px;

          margin-bottom: 29px;
        }
      }

      .right {
        width: 420px;

        ul {
          display: flex;
          flex-wrap: wrap;

          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 170px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 19px;
            color: #ffffff;
            line-height: 37px;
            margin-right: 79px;

            .title {
              margin-bottom: 6px;
            }

            &:nth-child(2n) {
              margin-right: 0;
            }

            &:nth-child(n-2) {
              margin-bottom: 13px;
            }
          }
        }
      }
    }

    .bottom {
      height: 114px;
      border-top: 1px solid #738099;

      background-color:yellow &:nth-child(1) {
        margin-bottom: 13px;
      }

      &:nth-child(2) {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 18px;
        color: #738099;
        line-height: 126px;
      }
    }
  }
}

.footer-max {
  width: 100%;
  background-color: #0a1633;
  padding-top: 80px;
  box-sizing: border-box;

  .top {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .left {
      width: 20%;

      .phone {
        h3:nth-of-type(1) {
          font-size: 16px;
          font-weight: 400;
          color: #dbeaff;

          img {
            width: 16px;
            height: 16px;
            margin-right: 9px;
          }
        }

        h3:nth-of-type(2) {
          font-size: 42px;
          font-weight: bold;
          color: #dbeaff;
          margin-top: 10px;
        }
      }

      .phone-wrap {
        display: flex;
        align-items: center;
        margin-top: 26px;

        div {
          font-size: 16px;
          font-weight: 400;
          color: #dbeaff;
        }
      }

      .kefu-btn {
        width: 162px;
        height: 48px;
        line-height: 48px;
        border-radius: 24px;
        background: #0b6fff;
        color: #ffffff;
        font-weight: 400;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        cursor: pointer;

        img {
          width: 18px;
          height: 18px;
          display: inline-block;
          margin-right: 10px;
        }
      }
    }

    .center {
      width: 60%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      ul {
        width: 20%;

        li:nth-of-type(1) {
          font-weight: 400;
          color: #dbeaff;
          opacity: 0.8;
          font-size: 18px;
          margin: 0 0 10px;
          cursor: pointer;
          height: 36px;
          line-height: 36px;
        }

        li {
          text-align: center;
          color: #dbeaff;
          line-height: 36px;
          opacity: 0.5;
          font-weight: 400;
          font-size: 16px;
          margin: 5px 0;
        }
      }
    }

    .right {
      width: 20%;

      h3 {
        font-weight: 400;
        color: #dbeaff;
        opacity: 0.8;
        font-size: 18px;
        margin-bottom: 10px;
        height: 36px;
        line-height: 36px;
      }

      .lunbo {
        height: 220px;
        overflow: hidden;

        .el-carousel__container {
          display: flex !important;
        }

        .el-carousel__item {
          display: flex;
          justify-content: space-between;

          & > div {
            width: 30%;

            & > div {
              position: relative;

              .Mask-bg {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                transition: 0.3s;
                background-color: rgba(0, 0, 0, 0.5);
              }
            }

            img {
              width: 100%;
            }

            p {
              margin-top: 20px;
              text-align: center;
              font-size: 16px;
              color: #dbeaff;
              font-weight: 400;
              opacity: 0.3;
              transition: 0.3s;
            }
          }

          & > div:hover {
            & > div {
              .Mask-bg {
                background-color: rgba(0, 0, 0, 0);
              }
            }

            p {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  & > span {
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.1);
    margin-top: 30px;
  }

  .bottom {
    max-width: 1400px;
    margin: 0 auto;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 16px;
      color: #dbeaff;
      opacity: 0.5;
      font-weight: 400;
    }

    a {
      color: #dbeaff;
    }
  }
}

.footer-max.mobile {
  padding-top: 60px;

  .top {
    width: 100%;

    .left {
      width: 100%;

      .telphone {
        text-align: center;

        p {
          font-size: 14px;
          color: #dbeaff;
        }

        h3 {
          font-size: 34px;
          color: #dbeaff;
          font-weight: bold;
          margin: 10px 0;
        }
      }

      .phone {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
          font-size: 18px !important;
          margin-top: 0 !important;
        }
      }

      .kefu-btn {
        margin: 20px auto 0;
      }
    }
  }

  .bottom {
    display: block;
    text-align: center;
    padding: 15px 0;
    box-sizing: border-box;
    height: auto;

    p {
      font-size: 12px;
      width: 60%;
      margin: 0 auto;
    }
  }
}
</style>
